import React, { useMemo } from 'react';
import classNames from 'classnames';
import { IItem, Items } from '../LanguageSelector.types';
import { testIds } from '../constants';
import { ReactComponent as ArrowIcon } from './assets/arrowIcon.svg';
import style from './style/LanguageSelector.scss';

type LanguageSelectorDropDownHandleProps = {
  onClick?: () => void;
  item: IItem;
  className?: string;
  showArrow: boolean;
  showText: boolean;
};

export const LanguageSelectorDropDownHandle: React.FC<LanguageSelectorDropDownHandleProps> = ({
  onClick,
  item,
  children,
  className,
  showArrow,
  showText,
  ...props
}) => {
  return (
    <div
      data-testid={testIds.dropdownHandleContainer}
      className={classNames([style.dropdown, className])}
      {...props}
    >
      <Option
        item={item}
        data-testid={testIds.dropdownHandle}
        showArrow={showArrow}
        showText={showText}
        className={style.dropdownHandle}
        onClick={onClick}
      />
      {children}
    </div>
  );
};

export const Option: React.FC<{
  item: IItem;
  showArrow: boolean;
  showText?: boolean;
  'aria-label'?: string;
  'data-testid'?: string;
  role?: string;
  onClick?: React.MouseEventHandler;
  className?: string;
}> = ({
  item,
  showArrow,
  showText = true,
  'data-testid': dataTestId,
  'aria-label': ariaLabel,
  role,
  onClick,
  className,
}) => (
  <div
    aria-label={ariaLabel}
    role={role}
    onClick={onClick}
    className={className}
    data-testid={dataTestId}
  >
    {item.flag && <FlagContainer flag={item.flag} alt={item.label} />}
    {item.text && showText && <LabelContainer text={item.text} />}
    {showArrow && <ArrowContainer />}
  </div>
);

export const FlagContainer = ({ flag, alt }: { flag: string; alt: string }) => (
  <div className={style.flagContainer} aria-hidden="true">
    <img src={flag} alt={alt} />
  </div>
);

export const LabelContainer = ({ text }: { text: string }) => (
  <div className={style.label} role="link">
    {text}
  </div>
);

export const ArrowContainer = () => (
  <>
    <div className={style.spacer}></div>
    <div aria-hidden="true" className={style.arrow}>
      <ArrowIcon />
    </div>
  </>
);

export function useOrganizeItems(items: Items) {
  const [selected, ...options] = useMemo(
    () =>
      items.reduce<Array<IItem>>((acc, current) => {
        acc[current.selected ? 'unshift' : 'push'](current);
        return acc;
      }, []),
    [items],
  );

  return [selected, ...options];
}
