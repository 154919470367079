import React, { useState } from 'react';
import classNames from 'classnames';
import { LanguageSelectorLayoutProps, IItem } from '../LanguageSelector.types';
import { testIds } from '../constants';
import {
  LanguageSelectorDropDownHandle,
  useOrganizeItems,
  Option,
} from './LanguageSelectorDropDownCommon';
import style from './style/LanguageSelector.scss';
import { KeyboardHandler } from './KeyboardHandler';
import { getAriaLabel } from './common/AriaLabelGetter';
import { useDropdownHasSpace } from './hooks/useDropdownHasSpace';

export const LanguageSelectorDropdown = ({
  translate,
  items,
  onChange,
  extraOptions,
}: LanguageSelectorLayoutProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [itemIndex, setItemIndex] = useState(0);
  const [shouldOpenUp, listRef] = useDropdownHasSpace(isOpen);
  const options = useOrganizeItems(items);

  const ariaLabel = getAriaLabel(translate, options[0].label);

  const navigateUp = () => {
    setItemIndex(Math.max(0, itemIndex - 1));
  };

  const navigateDown = () => {
    setItemIndex(Math.min(items.length - 1, itemIndex + 1));
  };

  const onClickArrowDownKey = () => {
    if (shouldOpenUp) {
      navigateUp();
    } else {
      navigateDown();
    }
  };

  const onClickArrowUp = () => {
    if (shouldOpenUp) {
      navigateDown();
    } else {
      navigateUp();
    }
  };

  const onReturnOrSpace = () => {
    if (itemIndex) {
      onChange(items[itemIndex]?.value);
      closeDropdown();
    } else {
      setIsOpen(!isOpen);
    }
  };

  const closeDropdown = () => {
    setIsOpen(false);
    setItemIndex(0);
  };

  const onClick = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      setItemIndex(shouldOpenUp ? items.length - 1 : 0);
    }
  };

  const getOptionClickHandler = (index: number, item: IItem) => () => {
    return index > 0 ? onChange(item.value) : onClick();
  };

  return (
    // eslint-disable-next-line jsx-a11y/role-supports-aria-props
    <KeyboardHandler
      tabIndex={0}
      aria-haspopup="true"
      aria-expanded={isOpen}
      role="listbox"
      aria-label={ariaLabel}
      data-testid={testIds.container}
      onBlur={closeDropdown}
      onArrowKeyDown={onClickArrowDownKey}
      onArrowKeyUp={onClickArrowUp}
      onEscape={closeDropdown}
      onReturnOrSpace={onReturnOrSpace}
    >
      <LanguageSelectorDropDownHandle
        onClick={onClick}
        item={options[0]}
        className={classNames({
          [style.open]: isOpen,
        })}
        showArrow={!!extraOptions.showArrow}
        showText={true}
      >
        <div
          role="menu"
          className={classNames([style.list], { [style.up]: shouldOpenUp })}
          ref={listRef}
        >
          {options.map((item, index) => {
            return (
              <Option
                key={item.value}
                item={item}
                aria-label={item.label}
                showArrow={index === 0}
                role="menuitem"
                onClick={getOptionClickHandler(index, item)}
                className={classNames([
                  style.item,
                  {
                    [style.focus]: index === itemIndex,
                    [style.selected]: index === 0,
                  },
                ])}
              />
            );
          })}
        </div>
      </LanguageSelectorDropDownHandle>
    </KeyboardHandler>
  );
};
