import * as React from 'react';
import classNames from 'classnames';
import {
  LanguageSelectorProps,
  DisplayMode,
  LanguageSelectorLayoutProps,
} from '../LanguageSelector.types';
import { LanguageSelectorNativeDropdown } from './LanguageSelectorNativeDropDown';
import style from './style/LanguageSelector.scss';
import { LanguageSelectorHorizontal } from './LanguageSelectorHorizontal';
import { LanguageSelectorDropdown } from './LanguageSelectorDropDown';

const selectors: {
  [key in DisplayMode]: React.FC<LanguageSelectorLayoutProps>;
} = {
  dropdown: LanguageSelectorDropdown,
  nativeDropdown: LanguageSelectorNativeDropdown,
  horizontal: LanguageSelectorHorizontal,
} as const;

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  id,
  items,
  displayMode,
  onChange,
  alignItems,
  extraOptions,
  translate,
}) => {
  if (!items || items.length < 2) {
    return <div id={id}></div>;
  }
  const Selector = selectors[displayMode];

  return (
    <div id={id} className={classNames(style.container, style[alignItems])}>
      <Selector
        translate={translate}
        items={items}
        onChange={onChange}
        alignItems={alignItems}
        extraOptions={extraOptions}
      />
    </div>
  );
};

export default LanguageSelector;
